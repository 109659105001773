import React, { memo, useCallback, useState } from "react";

import { Button, NumberInput, TextInput } from "@mantine/core";

import styles from "./styles.module.scss";
import { useForm } from "@mantine/form";
import toast from "react-hot-toast";

const FeedManageModal = memo(({ innerProps }) => {
  const { onSubmit, feedData } = innerProps;

  const [isRequested, setIsRequested] = useState(false);

  const feedForm = useForm({
    initialValues: {
      name: feedData.name,
      limitVideos:
        typeof feedData?.limitVideos === "number" ? feedData.limitVideos : "",
      filename: feedData.filename,
    },

    validate: {
      name: (value) => (!value ? "Empty field" : null),

      filename: (value) =>
        !value
          ? "Empty field"
          : value.includes(".")
          ? "The file name must be without an extension"
          : null,
    },
  });

  const updateFeedRequest = useCallback(async () => {
    try {
      setIsRequested(true);

      await onSubmit(feedForm.values);
    } catch (error) {
      toast.error(error?.message || "Server side error");
    } finally {
      setIsRequested(false);
    }
  }, [onSubmit, feedForm.values]);

  const onDisabledBtn = useCallback(async () => {
    if (!feedForm.values.name || !feedForm.values.filename) {
      return true;
    } else {
      return false;
    }
  }, [feedForm.values]);

  return (
    <div className={styles.modalWrapper}>
      <form onSubmit={feedForm.onSubmit((values) => updateFeedRequest(values))}>
        <TextInput
          {...feedForm.getInputProps("name")}
          label="Name"
          placeholder="For example, 'categoryAnimals'"
          required
        />
        <TextInput
          label="File name (in development)"
          {...feedForm.getInputProps("filename")}
          placeholder="For example, 'mrssAnimals'"
          required
          disabled
        />
        <NumberInput
          label="Number of videos in the feed"
          placeholder="An empty field with an unlimited number"
          {...feedForm.getInputProps("limitVideos")}
          color="cyan"
          clearable
        />
        <Button
          gradient={{ from: "grape", to: "cyan", deg: 90 }}
          variant="gradient"
          className={styles.submitBtn}
          type="submit"
          disabled={onDisabledBtn}
          loading={isRequested}
          sx={{
            marginTop: "15px",
          }}
        >
          Send
        </Button>
      </form>
    </div>
  );
});

export default FeedManageModal;
